import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Arrow from './images/arrow.inline.svg';

const styles = {
  base: 'inline-flex items-baseline leading-none font-bold transition-colors duration-200 group',
  size: {
    md: 'text-lg',
    sm: 'text-base',
  },
  theme: {
    'primary-deep-blue':
      'text-primary-deep-blue hover:text-secondary-pink-hover group-hover:text-secondary-pink-hover',
    'secondary-blue':
      'text-secondary-blue hover:text-secondary-pink-hover group-hover:text-secondary-pink-hover',
  },
};

const Link = ({
  className: additionalClassName,
  tag: Tag,
  theme,
  size,
  withArrow,
  to,
  children,
  ...props
}) => {
  const className = classNames(
    theme && size && styles.base,
    styles.theme[theme],
    styles.size[size],
    additionalClassName
  );

  const content = (
    <>
      {withArrow ? <span>{children}</span> : children}
      {withArrow && (
        <Arrow className="ml-2 transition-transform duration-200 group-hover:animate-link-arrow" />
      )}
    </>
  );

  if (Tag) {
    return (
      <Tag className={className} {...props}>
        {content}
      </Tag>
    );
  }

  if (to.startsWith('/')) {
    return (
      <GatsbyLink className={className} to={to} {...props}>
        {content}
      </GatsbyLink>
    );
  }

  return (
    <a className={className} href={to} {...props}>
      {content}
    </a>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.string,
  to: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(styles.size)),
  theme: PropTypes.oneOf(Object.keys(styles.theme)),
  withArrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Link.defaultProps = {
  className: null,
  tag: null,
  to: null,
  size: null,
  theme: null,
  withArrow: false,
};

export default Link;
