import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Link from 'components/shared/link';
import Logo from 'images/logo.inline.svg';
import { cloudUrl } from 'utils/api';
import filterNonRootItems from 'utils/filter-non-root-items';
import { simplifyBigNumber } from 'utils/utils';

import GithubIcon from '../footer/icons/github.inline.svg';

import Burger from './images/burger.inline.svg';

const styles = {
  base: {
    wrapper: 'relative z-10 py-7 xl:text-sm xl:py-8 md:py-6',
    logo: 'xl:h-8 xl:w-[125px]',
    dropdownMenu: 'rounded whitespace-nowrap py-1.5 px-3.5 bg-primary-white border border-grey-10',
  },
  theme: {
    'primary-black': {
      wrapper: 'bg-primary-black text-primary-white',
      button: {
        theme: 'primary-white-filled',
      },
    },
    'primary-white': {
      wrapper: 'bg-primary-white text-primary-black',
      button: {
        theme: 'primary-black-filled',
      },
    },
    'grey-5': {
      wrapper: 'bg-grey-5 text-primary-black',
      button: {
        theme: 'primary-black-filled',
      },
    },
  },
};

const Header = ({ theme, onBurgerClick, className: additionalClassname }) => {
  const {
    wp: {
      globalFields: {
        globalFields: { headerButtonText, headerButtonUrl },
      },
    },
    wpMenu: {
      menuItems: { nodes },
    },
    stargazers: { starsCount },
  } = useStaticQuery(graphql`
    {
      stargazers {
        starsCount
      }
      wp {
        globalFields {
          globalFields {
            headerButtonText
            headerButtonUrl
          }
        }
      }
      wpMenu(slug: { eq: "header" }) {
        menuItems {
          nodes {
            label
            path
            parentId
            target
            childItems {
              nodes {
                label
                path
                target
              }
            }
          }
        }
      }
    }
  `);

  const menuItems = filterNonRootItems(nodes);

  return (
    <header
      className={classNames(
        styles.base.wrapper,
        styles.theme[theme].wrapper,
        additionalClassname,
        'header'
      )}
    >
      <Container className="pt-safe max-w-[1366px] xl:max-w-[1216px] lg:max-w-[944px] md:pt-4">
        <nav className="relative flex items-center justify-between" aria-label="Global">
          <Link to="/">
            <span className="sr-only">Browserless</span>
            <Logo className={classNames(styles.base.logo, styles.theme[theme].logo)} />
          </Link>

          <ul className="flex space-x-11 xl:space-x-10 lg:hidden">
            {menuItems.map(({ label, path, target, childItems }, index) => (
              <li className="relative leading-none group" key={index}>
                <Link
                  className="font-medium leading-none transition-colors duration-200 group-hover:text-secondary-pink-hover"
                  to={path}
                  target={target}
                >
                  {label}
                </Link>
                {childItems?.nodes?.length > 0 && (
                  // This div is required in order to add invisible gap between a link and dropdown menu where the user can hover and dropdown menu will not hide
                  <div className="absolute bottom-0 invisible pt-4 transition-all duration-200 translate-y-full opacity-0 -left-4 hover:opacity-100 hover:visible group-hover:opacity-100 group-hover:visible">
                    <ul
                      className={classNames(
                        styles.base.dropdownMenu,
                        styles.theme[theme].dropdownMenu
                      )}
                      style={{
                        boxShadow: '0 4px 8px rgba(13, 13, 13, 0.2)',
                      }}
                    >
                      {childItems.nodes.map(({ label, path, target }, index) => (
                        <li key={index}>
                          <Link
                            className="block font-medium leading-none transition-colors duration-200 hover:text-grey-40 text-primary-black py-2.5"
                            to={path}
                            target={target}
                          >
                            {label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>

          <div className="flex content-center flex-wrap lg:hidden gap-3">
            <div className="flex content-center flex-wrap">
              <Link
                className="transition-colors duration-200 hover:text-secondary-pink-hover flex gap-1"
                to="https://github.com/browserless/browserless"
                target="_blank"
              >
                <GithubIcon className="h-6 w-6" />
                <span className="xl:flex xl:content-center xl:flex-wrap">
                  {simplifyBigNumber(starsCount)}
                </span>
              </Link>
            </div>
            <Link
              className="block font-medium leading-none transition-colors duration-200 hover:!text-secondary-pink-hover  py-2.5"
              to={cloudUrl}
              target={cloudUrl}
            >
              Login
            </Link>
            <Button to={headerButtonUrl} size="sm" {...styles.theme[theme].button}>
              {headerButtonText}
            </Button>
          </div>

          <button className="hidden -mr-1 outline-none lg:block" type="button">
            <Burger onClick={onBurgerClick} />
          </button>
        </nav>
      </Container>
    </header>
  );
};

Header.propTypes = {
  theme: PropTypes.oneOf(Object.keys(styles.theme)).isRequired,
  onBurgerClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};

export default Header;
