import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const sizeClassNames = {
  sm: 'max-w-[800px] lg:px-4',
  lg: 'max-w-[1216px] xl:max-w-[944px] lg:max-w-none lg:mx-4',
};

const Container = ({ className: additionalClassName, children, size, ...otherProps }) => {
  const className = classNames(
    'relative mx-auto px-4 max-w-[1366px]',
    sizeClassNames[size],
    additionalClassName
  );

  return (
    <div className={className} {...otherProps}>
      {children}
    </div>
  );
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.keys(sizeClassNames)),
};

Container.defaultProps = {
  className: null,
  size: 'lg',
};

export default Container;
