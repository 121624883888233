import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  size: {
    xl: 'font-bold text-6xl xl:text-5xl md:text-4xl',
    lg: 'font-bold text-5xl xl:text-4xl md:text-3xl',
    md: 'font-semibold text-3xl xl:text-2xl',
    sm: 'font-semibold text-2xl xl:text-xl',
    xs: 'font-medium text-xl xl:text-xl',
  },
  theme: {
    'primary-white': 'text-primary-white',
    'primary-black': 'text-primary-black',
  },
};

const Heading = ({
  className: additionalClassName,
  tag: Tag,
  size,
  theme,
  asHTML,
  children,
  ...otherProps
}) => {
  const className = classNames(styles.size[size], styles.theme[theme], additionalClassName);

  if (asHTML) {
    return (
      <Tag className={className} dangerouslySetInnerHTML={{ __html: children }} {...otherProps} />
    );
  }

  return (
    <Tag className={className} {...otherProps}>
      {children}
    </Tag>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(styles.size)).isRequired,
  theme: PropTypes.oneOf(Object.keys(styles.theme)),
  asHTML: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Heading.defaultProps = {
  className: null,
  asHTML: false,
  theme: undefined,
};

export default Heading;
