import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import Link from 'components/shared/link';

import Close from './images/close.inline.svg';

const MobileMenu = ({ isOpen, onOverlayOrCloseClick }) => {
  const {
    wp: {
      globalFields: {
        globalFields: { headerButtonText, headerButtonUrl },
      },
    },
    wpMenu: {
      menuItems: { nodes: menuItems },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        globalFields {
          globalFields {
            headerButtonText
            headerButtonUrl
          }
        }
      }
      wpMenu(slug: { eq: "mobile" }) {
        menuItems {
          nodes {
            label
            path
            target
          }
        }
      }
    }
  `);

  return (
    <>
      {isOpen && (
        <div
          className="fixed top-0 bottom-0 left-0 right-0 z-40"
          aria-hidden="true"
          onClick={onOverlayOrCloseClick}
        />
      )}
      <div
        className={classNames(
          'fixed top-0 bottom-0 right-0 z-50 bg-primary-white w-full max-w-[340px] hidden lg:block translate-x-full transition-transform duration-300 border-l border-l-grey-10',
          isOpen && '!translate-x-0'
        )}
        style={{
          boxShadow: '-3px 4px 15px rgba(13, 13, 13, 0.1)',
        }}
      >
        <div className="h-screen px-10 pb-20 overflow-auto pt-23">
          <ul>
            {menuItems.map(({ label, path, target }, index) => (
              <li key={index}>
                <Link className="block py-5 font-semibold leading-none" to={path} target={target}>
                  {label}
                </Link>
              </li>
            ))}
          </ul>

          <Button
            className="!flex mt-5"
            to={headerButtonUrl}
            size="sm"
            theme="primary-black-filled"
          >
            {headerButtonText}
          </Button>
        </div>

        <button className="absolute outline-none top-6 right-4" type="button">
          <Close onClick={onOverlayOrCloseClick} />
        </button>
      </div>
    </>
  );
};

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  onOverlayOrCloseClick: PropTypes.func.isRequired,
};

MobileMenu.defaultProps = {
  isOpen: false,
};

export default MobileMenu;
