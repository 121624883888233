import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Container from 'components/shared/container';
import Link from 'components/shared/link';
import Logo from 'images/logo.inline.svg';
import filterNonRootItems from 'utils/filter-non-root-items';

import GithubIcon from './icons/github.inline.svg';
import SlackIcon from './icons/slack.inline.svg';
import TwitterIcon from './icons/twitter.inline.svg';

const Footer = () => {
  const {
    wp: {
      globalFields: {
        globalFields: { githubUrl, twitterUrl, slackUrl, privacyPolicyUrl, termsOfServiceUrl },
      },
    },
    wpMenu: {
      menuItems: { nodes },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        globalFields {
          globalFields {
            githubUrl
            twitterUrl
            slackUrl
            privacyPolicyUrl
            termsOfServiceUrl
          }
        }
      }
      wpMenu(slug: { eq: "footer" }) {
        menuItems {
          nodes {
            label
            path
            parentId
            target
            childItems {
              nodes {
                label
                path
                target
              }
            }
          }
        }
      }
    }
  `);

  const menuItems = filterNonRootItems(nodes);

  const currentYear = new Date().getFullYear();

  return (
    <footer className="pt-24 pb-9 bg-primary-black xl:pt-16 xl:pb-8 xl:text-sm md:pt-11 footer">
      <Container className="pb-safe">
        <div className="grid grid-cols-12 gap-x-8 xl:gap-x-7 lg:gap-x-4 md:block">
          <div className="col-span-4 xl:col-span-3 lg:col-span-12 lg:flex lg:items-center lg:justify-between md:mb-10">
            <Link to="/">
              <span className="sr-only">Browserless</span>
              <Logo className="text-primary-white xl:h-8 xl:w-[125px]" />
            </Link>
            <ul className="flex mt-16 space-x-3 text-primary-white xl:space-x-3.5 xl:mt-12 lg:mt-0">
              <li>
                <Link
                  className="transition-colors duration-200 hover:text-secondary-pink-hover"
                  to={githubUrl}
                  target="_blank"
                >
                  <GithubIcon className="xl:h-7 xl:w-7" />
                </Link>
              </li>
              <li>
                <Link
                  className="transition-colors duration-200 hover:text-secondary-pink-hover"
                  to={twitterUrl}
                  target="_blank"
                >
                  <TwitterIcon className="xl:h-7 xl:w-7" />
                </Link>
              </li>
              <li>
                <Link
                  className="transition-colors duration-200 hover:text-secondary-pink-hover"
                  to={slackUrl}
                  target="_blank"
                >
                  <SlackIcon className="xl:h-7 xl:w-7" />
                </Link>
              </li>
            </ul>
          </div>
          {menuItems.map(({ childItems }, index) => (
            <ul
              className={classNames(
                'text-primary-white space-y-5 mt-3 leading-none xl:mt-0 xl:spacing-x-4.5',
                {
                  'col-span-3 lg:col-span-5 lg:mt-11 md:mt-5': index !== menuItems.length - 1,
                  'col-span-2 lg:col-span-2 lg:mt-11 md:mt-5': index === menuItems.length - 1,
                }
              )}
              key={index}
            >
              {childItems.nodes.map(({ label, path, target }, index) => (
                <li key={index}>
                  <Link
                    className="transition-colors duration-200 hover:text-secondary-pink-hover"
                    to={path}
                    target={target}
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          ))}
        </div>
        <div className="relative grid grid-cols-12 text-sm mt-26 text-grey-40 gap-x-8 xl:mt-20 xl:gap-x-7 lg:gap-x-4 lg:flex lg:items-center lg:justify-between md:mt-8 md:pt-6 md:items-end md:before:h-px md:before:absolute md:before:top-0 md:before:-left-4 md:before:-right-4 md:before:bg-grey-40 xs:flex-col xs:items-start">
          <span className="col-span-3 xs:mt-5">&copy; {currentYear} Browserless</span>
          <div className="col-start-5 col-end-12 space-x-8 lg:space-x-7 xl:col-start-4 md:flex md:flex-col md:space-x-0 md:space-y-5 md:order-first">
            <Link
              className="transition-colors duration-200 hover:text-secondary-pink-hover"
              to={privacyPolicyUrl}
            >
              Privacy Policy
            </Link>
            <Link
              className="transition-colors duration-200 hover:text-secondary-pink-hover"
              to={termsOfServiceUrl}
            >
              Terms of Service
            </Link>
            <span>
              Creative design by{' '}
              <Link
                className="transition-colors duration-200 hover:text-secondary-pink-hover"
                to="https://pixelpoint.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pixel Point
              </Link>
            </span>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
