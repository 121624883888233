export const paddings = {
  top: {
    lg: 'pt-36 xl:pt-24 md:pt-14',
    md: 'pt-28 xl:pt-24 md:pt-14',
    sm: 'pt-14 xl:pt-14 md:pt-14',
  },
  bottom: {
    lg: 'pb-36 xl:pb-24 md:pb-14',
    md: 'pb-28 xl:pb-24 md:pb-14',
    sm: 'pb-14 xl:pb-14 md:pb-14',
  },
};

export const margins = {
  top: {
    lg: 'mt-36 xl:mt-24 md:mt-14',
    md: 'mt-28 xl:mt-24 md:mt-14',
    sm: 'mt-14 xl:mt-14 md:mt-14',
  },
};
