import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'components/shared/link';

const styles = {
  base: 'inline-flex items-center justify-center font-bold leading-none text-center whitespace-nowrap transition-colors duration-200',
  disabled: 'opacity-40 pointer-events-none',
  size: {
    lg: 'h-[56px] px-8',
    md: 'text-base h-12 px-16',
    sm: 'text-sm h-10 px-12 xl:h-9 xl:px-9',
    xs: 'px-2 py-0.5 text-xs leading-normal',
  },
  theme: {
    'primary-white-filled':
      'text-primary-black bg-primary-white hover:bg-secondary-pink-hover-light',
    'primary-black-filled': 'text-primary-white bg-primary-black hover:bg-primary-deep-blue',
    'primary-white-outline':
      'text-primary-white border-2 border-primary-white hover:border-secondary-pink-hover xl:border',
    'primary-black-outline':
      'text-primary-black border-2 border-primary-black hover:border-secondary-pink-hover xl:border',
    'grey-80': 'bg-grey-80 text-grey-10',
  },
};

const Button = ({
  className: additionalClassName,
  to,
  size,
  theme,
  disabled,
  children,
  ...otherProps
}) => {
  const className = classNames(
    styles.base,
    size && styles.size[size],
    theme && styles.theme[theme],
    disabled && styles.disabled,
    additionalClassName
  );

  const Tag = to ? Link : 'button';

  return (
    <Tag className={className} to={to} {...otherProps}>
      {children}
    </Tag>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(styles.size)),
  theme: PropTypes.oneOf(Object.keys(styles.theme)),
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  className: null,
  to: null,
  disabled: false,
  size: null,
  theme: 'primary-black-filled',
};

export default Button;
