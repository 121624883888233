/* eslint-disable import/prefer-default-export */
import get from 'lodash.get';
import fetch from 'node-fetch';

const getHostname = () => (typeof window !== 'undefined' ? window.location.hostname : '');

// eslint-disable-next-line no-nested-ternary
const baseUrl = getHostname().startsWith('deploy-preview')
  ? 'http://dev-api.browserless.io'
  : process.env.NODE_ENV === 'production'
  ? 'https://api.browserless.io'
  : 'http://browserless.localhost:4000';

export const cloudUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://api.browserless.io/login'
    : 'http://browserless.localhost:4000/login';

export const screenshotDemo = (url) =>
  fetch(`${baseUrl}/demo/screenshot`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ url }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const pdfDemo = (url) =>
  fetch(`${baseUrl}/demo/pdf`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ url }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const scrapeDemo = (keyWord) =>
  fetch(`${baseUrl}/demo/scrape`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ keyWord }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

const graphql = ({ query, variables }) =>
  fetch(`${baseUrl}/graphql`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ query, variables }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (get(res, 'errors.length', 0)) {
        throw new Error(res.errors[0].message);
      }
      const results = res.data;

      return results;
    });

export const getImages = () =>
  graphql({
    query: `
      query getImages {
        images {
          browser
          tag
          puppeteerVersion
        }
      }
    `,
  });

export const verifyEmail = ({ email }) =>
  graphql({
    query: `
  mutation verifyEmail(
    $email: String!
  ) {
    verifyEmail(
      email: $email
    ) {
      email
    }
  }
  `,
    variables: {
      email,
    },
  });

export const markEmailVerified = ({ email, secret }) =>
  graphql({
    query: `
  mutation setEmailVerification(
    $email: String!
    $secret: String!
  ) {
    setEmailVerification(
      email: $email
      secret: $secret
    ) {
      isVerified
    }
  }
  `,
    variables: {
      email,
      secret,
    },
  });

export const signupCloudUnits = ({
  fullName,
  company,
  useCase,
  email,
  token,
  password,
  stripeToken,
  plan,
  taxId,
  address,
  completedActionId,
}) =>
  graphql({
    query: `
      mutation signup($user: signUpSession, $completedActionId: String) {
        signupCloudUnits(user: $user, completedActionId: $completedActionId) {
          authToken
          requiresAction
        }
      }
    `,
    variables: {
      user: {
        fullName,
        company,
        useCase,
        email,
        password,
        plan,
        token,
        stripeToken,
        taxId,
        address,
      },
      completedActionId,
    },
  });

export const subscribeToBlog = async ({ email }) => {
  const response = await fetch(`${baseUrl}/blog/subscribe`, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
    method: 'POST',
  });

  return response.json();
};

export const sendContactEmail = ({
  email,
  fullName,
  hosting,
  useCase,
  company,
  subject,
  message,
}) =>
  graphql({
    query: `
    mutation contact (
      $email: String!
      $fullName: String!
      $hosting: contactEmailHostingOption
      $useCase: useCase
      $company: String
      $subject: contactEmailSubject!
      $message: String!
      ) {

      contact (
        email: $email
        fullName: $fullName
        hosting: $hosting
        useCase: $useCase
        company: $company
        subject: $subject
        message: $message
      ) {
        wasSent
      }
    }
  `,
    variables: {
      email,
      fullName,
      hosting,
      useCase,
      company,
      subject,
      message,
    },
  });

export const sleep = (time) => new Promise((r) => setTimeout(r, time));

export const setParam = (key, value) =>
  typeof window !== 'undefined' ? window.sessionStorage.setItem(key, value) : null;

export const getParam = (key) =>
  typeof window !== 'undefined' ? window.sessionStorage.getItem(key) : null;
